import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
} from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { PaletteMode } from "@mui/material";

interface ThemeContextType {
  themeMode: PaletteMode;
  toggleTheme: () => void;
}

const ThemeContext = createContext<ThemeContextType>({
  themeMode: "light",
  toggleTheme: () => {},
});

export const useThemeContext = () => useContext(ThemeContext);

export const ThemeProviderWrapper = ({ children }) => {
  const [themeMode, setThemeMode] = useState<PaletteMode>(
    (localStorage.getItem("theme") as PaletteMode) || "light"
  );

  useEffect(() => {
    document.documentElement.setAttribute("data-coreui-theme", themeMode);
  }, [themeMode]);

  const toggleTheme = () => {
    const newMode: PaletteMode = themeMode === "light" ? "dark" : "light";
    setThemeMode(newMode);
    localStorage.setItem("theme", newMode);
  };

  const muiTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: themeMode,
          primary: { main: "#9e9e9e" },
          background: {
            default: themeMode === "dark" ? "#121212" : "#ffffff",
            paper: themeMode === "dark" ? "#282933" : "#f5f5f5",
          },
          text: {
            primary: themeMode === "dark" ? "#ffffff" : "#000000",
            secondary: themeMode === "dark" ? "#b0bec5" : "#4f4f4f",
          },
        },
      }),
    [themeMode]
  );

  return (
    <ThemeContext.Provider value={{ themeMode, toggleTheme }}>
      <ThemeProvider theme={muiTheme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};
